<template>
  <div>
    <text-input
      type="text"
      label="Search client by Name"
      placeholder="Search client by Name"
      :required="true"
      icon="search"
      v-model="orgSearchForm.name"
      @keyup="searchOrgByName(orgSearchForm.name)"
    />
    <div class="scroll">
      <list-box :items="searchResults.value" @select="switchOrganisation" />
    </div>
    <md-button @click="viewAllOrg">View All</md-button>
  </div>
</template>
<script>
export default {
  name: "OrganisationSearch",
  inject: [
    "searchResults",
    "orgSearchForm",
    "searchOrgByName",
    "switchOrganisation",
    "viewAllOrg",
  ],
};
</script>
