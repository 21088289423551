import SearchOrganisationService from "../../../services/module-services/organisation/SearchOrganisationService";
import NavigationService from "../../../services/common-services/NavigationService";
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);
import { computed } from "@vue/composition-api";
import { mapState } from "vuex";

const SearchOrganisationHandler = {
  data() {
    return {
      searchResults: [],
    };
  },
  mixins: [SearchOrganisationService, NavigationService],
  created() {
    this.searchResults = this.organisations;
  },
  computed: {
    ...mapState({
      organisations: (state) => {
        let arrOrgs = state.profile.userProfile.organisations.map((org) => {
          var tenant = {
            id: org.id,
            name: org.name,
            isSuperOrg: org.isSuperOrg,
          };
          return tenant;
        });
        arrOrgs = [
          {
            id: "",
            name: "All Clients",
            isSuperOrg: false,
          },
        ].concat(arrOrgs);
        return arrOrgs;
      },
    }),
  },
  methods: {
    searchOrg(name) {
      if (name) {
        this.searchResults = this.organisations.filter((sr) =>
          sr.name.toLowerCase().includes(name.toLowerCase())
        );
      } else {
        this.searchResults = this.organisations;
      }
    },
    switchOrg(org) {
      sessionStorage.setItem("Org_Id", org.id);
      localStorage.setItem("Org_Id", org.id);
      this.refreshRoute();
    },
    viewAllOrg() {
      this.navigateTo("Clients");
      this.closeOrgSwitcher();
    },
  },
  inject: ["closeOrgSwitcher"],
  template: `<div><slot></slot></div>`,
  provide() {
    return {
      searchResults: computed(() => {
        return this.searchResults;
      }),
      orgSearchForm: this.orgSearchForm,
      searchOrgByName: this.searchOrg,
      switchOrganisation: this.switchOrg,
      viewAllOrg: this.viewAllOrg,
    };
  },
};
export default SearchOrganisationHandler;
export const withSearchOrganisationHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<SearchOrganisationHandler><WrappedComponent v-bind="$props"></WrappedComponent></SearchOrganisationHandler>`,
    components: { SearchOrganisationHandler, WrappedComponent },
  };
};
