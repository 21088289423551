import { mapActions } from "vuex";
import { OrganisationSearchParams } from "../../../models/Organisation";
export default {
  data() {
    return {
      orgSearchForm: new OrganisationSearchParams(),
    };
  },
  methods: {
    ...mapActions("organisations", ["searchOrganisationsWithQueryData"]),
    searchOrganisationsWithParams(orgSearchForm) {
      let searchParams = {
        name: orgSearchForm.name,
      };
      this.searchOrganisationsWithQueryData(searchParams);
    },
    clearOrgSearchForm() {
      return new OrganisationSearchParams();
    },
  },
};
