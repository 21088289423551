<template>
  <div class="user">
    <div class="photo">
      <file-view-handler
        :options="{
          feature: 'User',
          featureId: app.userProfile.id,
          category: 'PROFILE',
        }"
        v-slot="{ uploadedFiles }"
      >
        <img
          v-if="uploadedFiles[0] != null"
          :src="uploadedFiles[0].link"
          @error="handleImageError"
          alt="avatar"
        />
        <img v-else src="@/assets/img/avatar_default.jpg" />
      </file-view-handler>
    </div>

    <div class="user-info">
      <a
        :aria-expanded="!isClosed"
        data-toggle="collapse"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span>{{ title }}<b class="caret" /></span>
        <span style="margin-left: 5em">
          <small v-html="properties"></small>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="md-list nav">
            <slot>
              <li>
                <a class="nav-link">
                  <i class="fa fa-user"></i>
                  <p @click="myprofile">My Profile</p>
                </a>
              </li>
              <li>
                <a class="nav-link">
                  <i class="fa fa-user"></i>
                  <p @click="mysettings">My Settings</p>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { mapState } from "vuex";
import moment from "moment";
import FileViewHandler from "../../../handlers/common-handlers/FileViewHandler";

export default {
  components: {
    CollapseTransition,
    FileViewHandler,
  },
  inject: ["app", "Permissions", "handleImageError"],
  data() {
    return {
      isClosed: true,
      avatar: "@/assets/img/avatar_default.jpg",
      // "https://globecheckportal.azurewebsites.net/img/user_avatar.87b02a18.png",
    };
  },
  computed: {
    ...mapState({
      userClaims: (state) => state.profile.userClaims,
      screeningDate: (state) => state.dashboard.screeningDates,
    }),
    memberDate() {
      var md = sessionStorage.getItem("memberDate");
      return md ? moment(md, "MM/DD/YYYY HH:kk A").format("ll") : "";
    },
    title() {
      return (
        this.app.userProfile.firstName.charAt(0).toUpperCase() +
        this.app.userProfile.firstName.slice(1) +
        " " +
        this.app.userProfile.lastName.charAt(0).toUpperCase() +
        this.app.userProfile.lastName.slice(1)
      );
    },

    properties() {
      var prop;
      if (this.userClaims.includes(this.Permissions.IsSuperAdmin)) {
        prop = "GlobeChek Super Admin";
      } else if (this.userClaims.includes(this.Permissions.IsTechnician)) {
        prop = "GlobeChek Technician";
      } else if (this.userClaims.includes(this.Permissions.IsAdmin)) {
        prop = "GlobeChek Administrator";
      } else if (this.userClaims.includes(this.Permissions.IsPatient)) {
        prop =
          "Last Screening: " +
          this.screeningDate.createdAt +
          "<br/>Member Since: " +
          this.screeningDate.updatedAt;
      } else if (this.userClaims.includes(this.Permissions.IsDoctor)) {
        prop = "GlobeChek Reader";
      }
      return prop;
    },
  },
  methods: {
    getImageFromFiles(uploadFiles, uploadedFiles) {
      if (uploadFiles && uploadFiles.length > 0) {
        return uploadFiles[0].link;
      } else if (uploadedFiles && uploadedFiles.length > 0) {
        return uploadedFiles[0].link;
      }
    },
    clicked: function (e) {
      e.preventDefault();
    },
    myprofile() {
      this.$router.push("/globe-chek/profile");
    },
    mysettings() {
      this.$router.push("/globe-chek/mysettings");
    },
    toggleMenu: function () {
      this.isClosed = !this.isClosed;
    },
  },
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
