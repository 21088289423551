var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:[{ 'nav-open': _vm.$sidebar.showSidebar }]},[_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'My Dashboard',
          icon: 'home',
          path: '/globe-chek/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'My Messages',
          icon: 'mail_outline',
          path: '/globe-chek/Messages',
        }}}),_c('p',[_vm._v("READING CENTER")]),_c('has-permission',{attrs:{"permission":_vm.Permissions.PackagesRead}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Packages',
            icon: 'cloud_upload',
            path: '/globe-chek/Packages',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Screenings',
          icon: 'visibility',
          path: '/portal/Screenings',
        }}},[_c('has-permission',{attrs:{"permission":_vm.Permissions.ScreeningRead}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Screenings Available',
              icon: 'visibility',
              path: '/globe-chek/Screenings',
            }}})],1),_c('has-permission',{attrs:{"permission":_vm.Permissions.ScreeningsonholdRead}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Screenings On-Hold',
              icon: 'visibility',
              path: '/globe-chek/Onhold',
            }}})],1)],1),_c('has-permission',{attrs:{"permission":_vm.Permissions.IsDoctor}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Doctors',
            icon: 'local_hospital',
            path: '/globe-chek/providers',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Reports',
          icon: 'assignment_ind',
          path: '/portal/admin',
        }}},[_c('has-permission',{attrs:{"permission":_vm.Permissions.ReportsRead}},[_c('sidebar-item',{attrs:{"link":{
              name: 'My Reports',
              icon: 'assignment_ind',
              path: '/globe-chek/Reports',
            }}})],1)],1),(!this.userClaims.includes(this.Permissions.SuperAdmin))?_c('div',[_c('sidebar-item',{attrs:{"link":{
            name: 'Referrals',
            icon: 'local_hospital',
            path: '/portal/admin',
          }}},[_c('has-permission',{attrs:{"permission":_vm.Permissions.ReferralDoctorsRead}},[_c('sidebar-item',{attrs:{"link":{
                name: 'List Doctors',
                icon: 'local_hospital',
                path: '/Referrals',
              }}})],1)],1)],1):_vm._e(),_c('has-permission',{attrs:{"permission":_vm.Permissions.HistoryRead,"strict":true}},[_c('sidebar-item',{attrs:{"link":{
            name: 'History',
            icon: 'assignment_ind',
            path:
              '/globe-chek/patientHistory/' + this.screeningDate.patientID,
          }}})],1),_c('has-permission',{attrs:{"permission":_vm.Permissions.Support,"strict":true}},[_c('li',[_c('a',{staticClass:"nav-link",attrs:{"href":_vm.support.link,"target":"_blank"}},[_c('i',{staticClass:"md-icon md-icon-font md-theme-default"},[_vm._v("help")]),_c('p',[_vm._v(_vm._s(_vm.support.title))])])])]),_c('has-permission',{attrs:{"permission":_vm.Permissions.UserRead}},[_c('p',[_vm._v("ADMINISTRATIVE")])]),_c('sidebar-item',{attrs:{"link":{ name: 'Management', icon: 'stars', path: '/portal/admin' }}},[_c('has-permission',{attrs:{"permission":_vm.Permissions.ClientRead}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Client',
              icon: 'local_hospital',
              path: '/globe-chek/clients',
            }}})],1),_c('has-permission',{attrs:{"permission":_vm.Permissions.UserRead}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Users',
              icon: 'local_hospital',
              path: '/globe-chek/users',
            }}})],1),_c('has-permission',{attrs:{"permission":_vm.Permissions.DeviceRead}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Devices',
              icon: 'local_hospital',
              path: '/globe-chek/devices',
            }}})],1),_c('has-permission',{attrs:{"permission":_vm.Permissions.MasterDataRead}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Master Data',
              icon: 'local_hospital',
              path: '/globe-chek/MasterData',
            }}})],1)],1),_c('sidebar-item',{attrs:{"link":{ name: 'Reporting', icon: 'reorder', path: '' }}})],1)],2),_c('div',{staticClass:"main-panel scroll"},[_c('top-navbar'),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }