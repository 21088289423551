<template>
  <div :class="[{ 'nav-open': $sidebar.showSidebar }]" class="wrapper">
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <user-menu />

      <template slot="links">
        <sidebar-item
          :link="{
            name: 'My Dashboard',
            icon: 'home',
            path: '/globe-chek/dashboard',
          }"
        />

        <sidebar-item
          :link="{
            name: 'My Messages',
            icon: 'mail_outline',
            path: '/globe-chek/Messages',
          }"
        />

        <p>READING CENTER</p>

        <has-permission :permission="Permissions.PackagesRead">
          <!--PACKAGES-->
          <sidebar-item
            :link="{
              name: 'Packages',
              icon: 'cloud_upload',
              path: '/globe-chek/Packages',
            }"
          >
            <!-- <sidebar-item :link="{ name: 'Upload Tracking', icon: 'visibility', path: '/portal/admin/upload' }" />       -->
            <!-- <sidebar-item :link="{ name: 'Package Adjustments', icon: 'visibility', path: '/portal/admin/adjust' }" />   -->
          </sidebar-item>
        </has-permission>
        <sidebar-item
          :link="{
            name: 'Screenings',
            icon: 'visibility',
            path: '/portal/Screenings',
          }"
        >
          <has-permission :permission="Permissions.ScreeningRead">
            <sidebar-item
              :link="{
                name: 'Screenings Available',
                icon: 'visibility',
                path: '/globe-chek/Screenings',
              }"
            />
          </has-permission>
          <has-permission :permission="Permissions.ScreeningsonholdRead">
            <sidebar-item
              :link="{
                name: 'Screenings On-Hold',
                icon: 'visibility',
                path: '/globe-chek/Onhold',
              }"
            />
          </has-permission>
        </sidebar-item>
        <has-permission :permission="Permissions.IsDoctor">
          <sidebar-item
            :link="{
              name: 'Doctors',
              icon: 'local_hospital',
              path: '/globe-chek/providers',
            }"
          />
        </has-permission>
        <!--REPORTS-->

        <sidebar-item
          :link="{
            name: 'Reports',
            icon: 'assignment_ind',
            path: '/portal/admin',
          }"
        >
          <has-permission :permission="Permissions.ReportsRead">
            <sidebar-item
              :link="{
                name: 'My Reports',
                icon: 'assignment_ind',
                path: '/globe-chek/Reports',
              }"
            />
          </has-permission>
          <!-- <sidebar-item :link="{ name: 'Reports Aging', icon: 'assignment_ind', path: '/portal/admin/reports-aging' }" /> -->
        </sidebar-item>

        <!--DOCTORS #3c4858 - #404040 -->
        <div v-if="!this.userClaims.includes(this.Permissions.SuperAdmin)">
          <!-- <has-permission :permission="Permissions.HistoryRead"> -->
          <sidebar-item
            :link="{
              name: 'Referrals',
              icon: 'local_hospital',
              path: '/portal/admin',
            }"
          >
            <!-- <sidebar-item :link="{ name: 'Pending Registrations', icon: 'local_hospital', path: '/portal/admin/doctors-pending' }"/> -->
            <!-- <sidebar-item :link="{ name: 'Search Doctors', icon: 'local_hospital', path: '/portal/admin/doctor-search' }" /> -->
            <has-permission :permission="Permissions.ReferralDoctorsRead">
              <sidebar-item
                :link="{
                  name: 'List Doctors',
                  icon: 'local_hospital',
                  path: '/Referrals',
                }"
              />
            </has-permission>
            <!-- <sidebar-item :link="{ name: 'List Practices', icon: 'local_hospital', path: '/portal/admin/practices-list' }" /> -->
          </sidebar-item>
          <!-- </has-permission> -->
        </div>
        <has-permission :permission="Permissions.HistoryRead" :strict="true">
          <sidebar-item
            :link="{
              name: 'History',
              icon: 'assignment_ind',
              path:
                '/globe-chek/patientHistory/' + this.screeningDate.patientID,
            }"
          />
          <!-- </div> -->
        </has-permission>
        <has-permission :permission="Permissions.Support" :strict="true">
          <li>
            <a :href="support.link" class="nav-link" target="_blank">
              <i class="md-icon md-icon-font md-theme-default">help</i>
              <p>{{ support.title }}</p>
            </a>
          </li>
        </has-permission>
        <!-- </HasPermission> -->
        <!--<p align="Left" style="font-size: .7rem; color: #777; font-weight: 400; margin-left:10px;">PACKAGE MANAGEMENT</p>-->

        <!--<p align="Left" style="font-size: .7rem; color: #777; font-weight: 400; margin-left:10px;">PACKAGE MANAGEMENT</p>-->
        <has-permission :permission="Permissions.UserRead">
          <p>ADMINISTRATIVE</p>
        </has-permission>
        <!--USER MAINT -->
        <sidebar-item
          :link="{ name: 'Management', icon: 'stars', path: '/portal/admin' }"
        >
          <has-permission :permission="Permissions.ClientRead">
            <sidebar-item
              :link="{
                name: 'Client',
                icon: 'local_hospital',
                path: '/globe-chek/clients',
              }"
            />
          </has-permission>
          <has-permission :permission="Permissions.UserRead">
            <sidebar-item
              :link="{
                name: 'Users',
                icon: 'local_hospital',
                path: '/globe-chek/users',
              }"
            />
          </has-permission>
          <has-permission :permission="Permissions.DeviceRead">
            <sidebar-item
              :link="{
                name: 'Devices',
                icon: 'local_hospital',
                path: '/globe-chek/devices',
              }"
            />
          </has-permission>
          <has-permission :permission="Permissions.MasterDataRead">
            <sidebar-item
              :link="{
                name: 'Master Data',
                icon: 'local_hospital',
                path: '/globe-chek/MasterData',
              }"
            />
          </has-permission>
          <!-- <sidebar-item :link="{ name: 'Members', icon: 'local_hospital', path: '/portal/admin/members' }"/> -->
          <!-- <sidebar-item :link="{ name: 'Globes', icon: 'local_hospital', path: '/portal/admin/globes' }" /> -->
          <!-- <sidebar-item :link="{ name: 'Lists', icon: 'local_hospital', path: '/portal/admin/lists' }" /> -->
          <!-- <sidebar-item :link="{ name: 'Content', icon: 'local_hospital', path: '/portal/admin/content' }" /> -->
        </sidebar-item>
        <sidebar-item
          :link="{ name: 'Reporting', icon: 'reorder', path: '' }"
        />

        <!-- <sidebar-item
          :link="{ name: 'Settings', icon: 'settings', path: '/portal/admin/' }"
        /> -->
      </template>
    </side-bar>

    <div class="main-panel scroll">
      <top-navbar />

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <router-view />
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter" />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
//import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import HasPermission from "../../components/HasPermission.vue";
import { mapState } from "vuex";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    //MobileMenu,
    UserMenu,
    ZoomCenterTransition,
    HasPermission,
  },
  inject: ["Permissions"],
  data() {
    return {
      sidebarBackgroundColor: "white",
      sidebarBackground: "green",
      sidebarBackgroundImage: "", //"./img/sidebar-0.jpg"
      sidebarMini: true,
      sidebarImg: true,
    };
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
  mounted() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("sidebar");
      initScrollbar("sidebar-wrapper");
      //initScrollbar("main-panel");

      docClasses.add("perfect-scrollbar-on");
    } else {
      docClasses.add("perfect-scrollbar-off");
    }
    this.maximixeSidebar();
  },
  computed: {
    ...mapState({
      screeningDate: (state) => state.dashboard.screeningDates,
      userClaims: (state) => state.profile.userClaims,
    }),
    support() {
      var prop = { link: "", title: "" };

      if (this.userClaims.includes(this.Permissions.IsPatient)) {
        //prop = "https://globechek.freshdesk.com/support/home";
        prop.title = "Help Desk";
        prop.link = "http://patienthelp.globechek.com/support/home";
      } else if (this.userClaims.includes(this.Permissions.IsTechnician)) {
        prop.title = "Fresh Desk";
        prop.link = "http://technicianhelp.globechek.com/support/home";
      } else if (this.userClaims.includes(this.Permissions.IsDoctor)) {
        prop.title = "Fresh Desk";
        prop.link = "http://technicianhelp.globechek.com/support/home";
      }
      return prop;
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    maximixeSidebar() {
      this.$sidebar.toggleMinimize();
    },
  },
};
</script>

<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
.sidebar-p {
  color: white;
}
.nav-link {
  display: block;
  text-decoration: none;
  margin: 0 15px;
  border-radius: 3px;
  color: #3c4858 !important;
  white-space: nowrap;
  padding: 8px 10px;
  font-size: 13px;
  cursor: pointer;
  width: auto;

  &:hover {
    background: #3c4858;
  }
}
</style>
