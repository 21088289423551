<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <ul>
          <li><a href="https://globechek.com/" target="_blank">Company</a></li>

          <li>
            <a href="https://www.facebook.com/GlobeChek/" target="_blank"
              >Social</a
            >
          </li>
          <li>
            <!-- <a href="https://www.gcdeveye.com/request-an-appointment/"
              >Agreement</a
            > -->
          </li>
        </ul>
      </nav>

      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <a href="https://www.globechek.com" target="_blank" rel="noopener">
          <span style="">Globe</span>
          <span style="font-weight: bold">Chek</span>
        </a>
        <!-- &nbsp; -->
        <span style="padding-left: 5px; font-size: 12px">
          Inc. What we see</span
        >
        <span style="padding-left: 5px; font-size: 12px">
          <i class="material-icons">remove_red_eye</i> may save your
          sight!</span
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
