<template>
  <md-toolbar
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
    md-elevation="16"
    style="height: 50px"
    align="Center"
  >
    <div class="md-toolbar-row">
      <div v-if="isVisible">
        <HasPermission :permission="Permissions.IsSuperAdmin">
          <div class="btn-all-clients">
            <md-button @click="toggleOrgSwitcher">
              <i v-if="isAllClients" class="fa fa-th"></i>
              <span>{{
                !isAllClients ? "Client:&nbsp;" + OrgName : "All Clients"
              }}</span>
            </md-button>
            <div v-show="showOrgSwitcher" class="dropdown">
              <div>
                <organisation-search />
              </div>
            </div>
          </div>
        </HasPermission>
      </div>
      <div class="md-toolbar-section-end">
        <div>
          <md-list>
            <md-list-item
              v-if="this.userNotificationperference.allowPopUpNotification != 0"
            >
              <div
                v-if="this.msgnocount.value > 0"
                @click="gotoMessages"
                style="color: red; cursor: pointer"
              >
                <i class="fas fa-bell"></i>
                {{ this.msgnocount.value }}
              </div>
            </md-list-item>
            <md-list-item href="#" @click="showSwal('logout-confirmation')">
              <!--/reading-center-->
              <i class="material-icons">exit_to_app</i>
              <p class="hidden-sm">&nbsp;Logout</p>
            </md-list-item>
          </md-list>
        </div>
        <md-button
          :class="{ toggled: $sidebar.showSidebar }"
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          @click="toggleSidebar"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import Swal from "sweetalert2";
import { logout } from "../../services/common-services/SharedFunctionService";
import UserTitleService from "../../services/layout-services/UserTitleService";
import OrganisationSearch from "./../../components/Cards/OrganisationSearch.vue";
import { withSearchOrganisationHandler } from "../../handlers/module-handlers/organisation/SearchOrganisationHandler";
import { mapState, mapActions } from "vuex";

export default {
  inject: ["Permissions", "app", "msgnocount"],
  provide() {
    return {
      closeOrgSwitcher: this.closeOrgSwitcher,
    };
  },
  mixins: [UserTitleService],
  components: {
    OrganisationSearch: withSearchOrganisationHandler(OrganisationSearch),
  },
  mounted() {
    this.msgcount();
  },
  computed: {
    ...mapState({
      organisations: (state) => state.profile.userProfile.organisations,
      userNotificationperference: (state) =>
        state.profile.userNotificationperference,
    }),
    isVisible() {
      return (
        this.$route.path == "/globe-chek/users" ||
        this.$route.path == "/globe-chek/dashboard" ||
        this.$route.path == "/globe-chek/Packages" ||
        this.$route.path == "/globe-chek/Screenings" ||
        this.$route.path == "/globe-chek/Onhold" ||
        this.$route.path == "/globe-chek/providers" ||
        this.$route.path == "/globe-chek/Reports" ||
        this.$route.path == "/globe-chek/devices" ||
        this.$route.path == "/globe-chek/devices"
      );
    },

    OrgName() {
      const orgId =
        localStorage && localStorage.getItem("Org_Id")
          ? localStorage.getItem("Org_Id")
          : "";
      return orgId
        ? this.organisations.find((org) => org.id === orgId).name
        : "";
    },
    isAllClients() {
      return localStorage.getItem("Org_Id") == "";
    },
  },
  data() {
    return {
      showOrgSwitcher: false,
      msgcounter: false,
    };
  },
  watch: {
    msgcount: function (val) {
      this.msgcounter = val;
      console.log(val);
    },
  },
  methods: {
    ...mapActions("dashboard", ["getMessagesCount"]),
    ...mapActions("profile", ["getNotificationpreferences"]),
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleOrgSwitcher() {
      this.showOrgSwitcher = !this.showOrgSwitcher;
    },
    closeOrgSwitcher() {
      this.showOrgSwitcher = false;
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    msgcount() {
      this.getMessagesCount(this.app.userProfile.id).then((response) => {
        if (response != 0) {
          this.msgcounter = true;
        } else {
          this.msgcounter = false;
        }
      });
    },
    gotoMessages() {
      this.$router.push({ name: "Messages" });
    },

    // notification() {
    //   if (
    //     this.getNotificationpreferences(this.app.userProfile.id).then(
    //       (response) => {
    //         //console.log(response);
    //         if (response.allowPopUpNotification == 1) {
    //           this.getMessagesCount(this.userId).then((response) => {
    //             if (response != 0) {
    //               this.userNotificationperference.notificationpop = 1;
    //             }
    //           });
    //         } else {
    //           this.userNotificationperference.notificationpop = 0;
    //         }
    //       }
    //     )
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    showSwal(type) {
      if (type === "logout-confirmation") {
        Swal.fire({
          title: "LOGOUT",
          text: `Are you sure you want to logout?`,
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-info",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            logout();
          }
        });
      }
    },
  },
};
</script>
